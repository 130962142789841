var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row flex-wrap",style:({
    background: ("url(" + _vm.bg + ")"),
    backgroundSize: 'cover',
    height: '100%',
  }),attrs:{"id":"signIn"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 full-width flex-wrap flex-shrink-0 align-content-center justify-center"},[_vm._m(0),_c('v-card',{staticClass:"rounded-sm box-shadow-light ma-3 px-5 pb-10 mt-n6",staticStyle:{"min-height":"300px","border-radius":"8px !important"},attrs:{"id":"loginCard"}},[(_vm.isLoading)?[_c('div',{staticClass:"d-flex flex-column pt-6 flex-grow-1 justify-center align-center"},[_c('img',{staticStyle:{"width":"150px"},attrs:{"src":require("@/assets/images/folder.png")}}),_c('h2',{staticClass:"ft font-weight-bold pt-1"},[_vm._v("Setting Up Your Account")]),_c('h4',{staticClass:"ft text-center font-weight-medium pa-1"},[_vm._v(" Completing your account setup, please wait ... ")]),_c('v-progress-circular',{staticClass:"mt-3 mb-3",attrs:{"size":"40","color":"accent","indeterminate":""}})],1)]:[_c('div',{staticClass:"text-center animate__animated animate__fadeInDown d-flex flex-column justify-center pt-7 pl-1"},[_c('h4',{staticClass:"ft text-center font-weight-bold",staticStyle:{"font-size":"20px"}},[_vm._v(" Complete Account Setup ")]),_c('p',{staticClass:"text-center font-weight-medium ft pa-3"},[_vm._v(" Provide all necessary details to complete "),_c('br'),_vm._v("creating of your account ")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.completeAccount)}}},[_c('ValidationProvider',{attrs:{"name":"newPassword","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft",attrs:{"type":'text',"placeholder":"Enter A New Username","label":"Enter Your Account Username","outlined":"","error-messages":errors[0],"autofocus":true},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"newPassword","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2",attrs:{"type":_vm.fieldState.newPassword ? 'text' : 'password',"placeholder":"Enter a new password ","label":"New Password","append-icon":_vm.fieldState.newPassword ? 'visibility_off' : 'visibility',"outlined":"","error-messages":errors[0],"autofocus":true},on:{"click:append":function($event){return _vm.changeState('newPassword')}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirmPassword","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2",attrs:{"type":_vm.fieldState.confirmPassword ? 'text' : 'password',"placeholder":"Confirm your new password ","label":"Confirm New Password","append-icon":_vm.fieldState.confirmPassword
                      ? 'visibility_off'
                      : 'visibility',"outlined":"","error-messages":errors[0],"autofocus":true},on:{"click:append":function($event){return _vm.changeState('confirmPassword')}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)]}}],null,true)}),_c('v-card-actions',{staticClass:"justify-end mt-1 pl-0 pr-0 pb-2"},[_c('v-btn',{staticClass:"ft text-sm text-capitalize",attrs:{"type":"submit","disabled":_vm.isLoading,"loading":_vm.isLoading,"color":"primary","block":"","depressed":""}},[_vm._v("Create An Account ")])],1)],1)]}}])})]],2),_vm._m(1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-container d-flex mb-5 flex-row mt-n12 justify-center mb-2 pa-5 animate__animated animate__fadeInDown"},[_c('img',{staticStyle:{"width":"200px"},attrs:{"src":require("@/assets/logo.jpeg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-center"},[_c('span',{staticClass:"white--text ft"},[_vm._v("CS Info Systems ®")])])}]

export { render, staticRenderFns }